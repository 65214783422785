module.exports = {
  PORT: "3124",
  NODE_ENV: "development",

  SERVER_DEV_URL: "http://localhost:3131",
  SERVER_STAGING_URL: "https://nlb-api.cloudxier.dev",
  SERVER_PROD_URL: "https://fanta.nlb.co.id",

  CLIENT_DEV_HOST: "localhost",
  CLIENT_STAGING_HOST: "nlb.cloudxier.com",
  CLIENT_PROD_HOST: "nlb.co.id",

  CLIENT_DEV_URL: "http://localhost:3000",
  CLIENT_STAGING_URL: "https://nlb.cloudxier.com",
  CLIENT_PROD_URL: "https://nlb.co.id",

  MAIN_WEBSITE_TITLE: 'NLB | ',
  SITE_NAME: 'Nizertek Lintas Bumi'
}
